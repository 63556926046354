import React from "react"
import { graphql } from "gatsby"

import { withoutAuthentication } from "../../hoc/withAuthentication"
import { AccountLogin as Page } from "../../components/Account/Login/AccountLogin"

export const query = graphql`
  query {
    page: sanityPageAccountLogin {
      title
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

export default withoutAuthentication(({ data, ...props }) => <Page {...props} {...data} />)
